<template>
  <div>
    <div id="mySidenav" class="sidenav" style="z-index: 999999">

      <template v-if="isLoggedIn">
        <div style=" padding: 6px 9px; height: 60px; background-color: #2d3640; ">

          <img style="width: 60px; border: 1px solid #dde3eb; height: 60px;" class="avatar_image" :src=user.avatar_url >
          <span style="position: absolute; margin-top: 22px; margin-left: 10px; color: white; font-size: 18px;">{{ user.username }}</span>
          <img  class="closebtn" @click="closeNav" src="https://gcdn.bionluk.com/site/icon/aws/ic_close_white.svg">
        </div>

        <div class="hiddendiv20"></div>

        <div style="position: relative;">
          <ul class="nav-list">
            <li>
              <router-link :to="'/panel/mesajlar'" >
                <img  src="https://gcdn.bionluk.com/site/icon/aws/ic_commentt_gray.svg" >
                Mesajlar
              </router-link>
            </li>
            <li >
              <router-link :to="'/notifications'" >
                <img  style="height: 20px;" src="https://gcdn.bionluk.com/site/icon/aws/ic_zil_gray.svg" ><span style="margin-left:8px;">Bildirimler</span> </router-link>
            </li>
            <li>
              <router-link :to="'/settings'" ><img src="https://gcdn.bionluk.com/site/icon/aws/ic_settings_gray.svg"><span style="margin-left:3px;">Ayarlar</span></router-link>
            </li>
            <li><router-link :to="'/settings/seller'"><img src="https://gcdn.bionluk.com/site/icon/aws/ic_accessibility_gray.svg" ><span style="margin-left:5px;">Özgür Çalış</span></router-link>
            </li>
            <li @click="logout()">
              <img src="https://gcdn.bionluk.com/site/icon/aws/ic_exit_to_app_gray.svg" ><span style="margin-left:5px;">Çıkış</span>
            </li>

          </ul>
        </div>
      </template>

      <template v-else>



        <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>

        <div class="hiddendiv20"></div> <div class="hiddendiv20"></div>
        <router-link :to="prefixPath">Anasayfa</router-link>
        <router-link :to="'/freelancer-bul'">Kategorilere Göz At</router-link>
        <router-link v-if="!user.seller.isSeller" :to="'/become_a_freelancer'">Özgür Çalış</router-link>
        <router-link :to="'/register'">Kaydol</router-link>
        <router-link :to="'/login'">Giriş Yap</router-link>
        <div class="hiddendiv20"></div>
        <router-link :to="'/destek'" style="font-size: 14px;">Yardım</router-link>
        <router-link :to="'/blog'" style="font-size: 14px;">Blog</router-link>

      </template>

    </div>



    <div v-if="user.uuid" style="position:fixed; bottom: 0; width: 100%; z-index: 99;">



    <div style="clear: both; text-align: center; display:flex; background-color: #2d3640;  width: 100%; height: 50px;">
      <div style="width: 100%; display:flex;">

        <router-link :to="'/bugun'" class="nav_tap_mobile feed">
          <img style="width: 16px; height: 16px;" src="">
          <div>AKIŞ</div>
        </router-link>

        <router-link :to="'/freelancer-bul'"  class="nav_tap_mobile marketplace">
          <img style="width: 16px; height: 16px;" src="">
          <div>KEŞFET</div>
        </router-link>

        <router-link :to="'/panel'" class="nav_tap_mobile workstation">
          <img style="width: 16px; height: 16px;"  src="">
          <div>PANEL</div>
        </router-link>

        <router-link  :to="'/mprofile'" class="nav_tap_mobile profile">
          <span v-if="user.unreadNotificationCount > 0" class="mobileProfileDot" style=" position: absolute; margin-left: 22px; margin-top: 6px; z-index: 9"><span style="border-radius: 50%;width: 5px; height: 5px; background-color: #f2b46d; display: block; border: 1px solid #f2b46d;"> </span></span>
          <img style="width: 16px; height: 16px;" :src="$root.CDN_SITE + 'icon/ic_account_circle_black.svg'">
          <div>DİĞER</div>
        </router-link>

      </div>
    </div>
    </div>
  </div>

</template>

<script>
  import mixin from './mixin';

  export default {
    mixins: [mixin],
    name: "src-pages-header-shared-regular_mobile",
    data() {
      return {
        mobilePopSound: null,
        activePopSound: null
      }
    },
    methods: {
      openNav() {
        document.getElementById("mySidenav").style.width = "100%";
      },
      closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      }
    }
  }
</script>

<style scoped>
  .nav_tap_mobile{flex: 1; color: #8b95a1; font-size: 10px; letter-spacing: -0.2px;


  }

  .nav_tap_mobile:hover{text-decoration: none}

  .nav_tap_mobile > img{

    margin-top: 10px;


  }


  .nav_tap_mobile.router-link-active > img{

    margin-top: 10px;


  }


  .nav_tap_mobile > div{
    color: #8b95a1; font-size: 10px; letter-spacing: -0.2px; padding-top: 4px;
    text-transform: uppercase;
  }








  .nav_tap_mobile.router-link-active{
    color: #fff; font-size: 10px; letter-spacing: -0.2px;
  }

  .nav_tap_mobile.router-link-active > div{

    color: #fff; font-size: 10px; letter-spacing: -0.2px;


  }




  .nav_tap_mobile.marketplace > img{

    content:url("https://gcdn.bionluk.com/site/icon/tansel_mobile_explore.png");
  }

  .nav_tap_mobile.router-link-active.marketplace > img{

    content:url("https://gcdn.bionluk.com/site/icon/tansel_mobile_explore_active.png");
  }


  .nav_tap_mobile.feed > img{


    content:url("https://gcdn.bionluk.com/site/icon/tansel_mobile_feed.png");
  }

  .nav_tap_mobile.router-link-active.feed > img {


    content:url("https://gcdn.bionluk.com/site/icon/tansel_mobile_feed_active.png");
  }


  .nav_tap_mobile.workstation > img {

    content:url("https://gcdn.bionluk.com/site/icon/tansel_mobile_workstation.png");
  }

  .nav_tap_mobile.router-link-active.workstation > img{

    content:url("https://gcdn.bionluk.com/site/icon/tansel_mobile_workstation_active.png");
  }


  .nav_tap_mobile.profile > img{

    content:url("https://gcdn.bionluk.com/site/icon/tansel_mobile_more.png");
  }

  .nav_tap_mobile.router-link-active.profile > img{

    content:url("https://gcdn.bionluk.com/site/icon/tansel_mobile_more_active.png");
  }



  /* The side navigation menu */
  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 2; /* Stay on top */
    top: 0;
    left: 0;
    background-color: #f5f5f5; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    /* Place content 60px from the top */
    transition: 0.3s; /* 0.5 second transition effect to slide in the sidenav */
  }

  /* The navigation menu links */
  .sidenav a {
    padding: 2px 4px 2px 16px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;

    display: block;
    opacity: 0.8;


    line-height: 1.82;

  }

  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover, .offcanvas a:focus{

  }

  .sidenav a > img{
    opacity: 1;

  }

  /* Position and style the close button (top right corner) */
  .sidenav .closebtn {
    position: absolute;
    cursor: pointer;
    right: 0;
    margin-top: 10px;

    margin-right: 20px;
  }


  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }



  #containermobile {
    height: 40px;


    display: flex;                  /* establish flex container */
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    justify-content: space-around; /* switched from default (flex-start, see below) */
    background-color: #2d3640;
  }


  #containermobile > div {

    align-self: center;
    align-content: center;
    align-items: center;
    /*width: 50%;

     border: 2px dashed red; */
  }

  input[type="text"].mainSearch {

    width: 100%!important;
    background: #ffffff url(https://gcdn.bionluk.com/site/icon/aws/search_siyah.svg) no-repeat 2.5% center;
    text-align: left;
    border: 0;
    border-bottom: 1px solid #bfc8d2;
    padding: 8px 0 8px 48px;
    margin-bottom: 6px;
    font-size: 18px;
    box-sizing: border-box;
  }


  .numberCircle {

    border-radius: 50%;
    width: 20px;

    background-color: #f2b46d;


  }
  .numberCircle span {

    text-align: center;
    line-height: 20px;
   padding-left: 1px;
    display: block;
    color: #2d3640;
    font-weight: 500;

  }




</style>
